'use strict';

const brand = require('./brand');
const sliders = require('./sliders');

const fixLazyLoad = function () {
    $('.lazy').addClass('lazyload');
};

const uiAccordion = function () {
    // Activate any global accordion
    $('.auto-init-accordion').each(function () {
        $(this).accordion({
            active: $(this).data('active'),
            heightStyle: 'content',
            collapsible: true
        });
    });
};

module.exports = {
    brand: brand.init(),
    sliders: sliders.init(),
    fixLazyLoad: fixLazyLoad,
    uiAccordion: uiAccordion
};
