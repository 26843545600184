'use strict';

function brandMenu() {
    $('.pt_brand .brand-nav > ul > li').hover(function () {
        $(this).children('ul').stop(true, false, true).fadeToggle(300);
    });
    $('.pt_brand .brand-nav > ul > li > a').on('keydown', function (e) {
        if (e.which === 13) {
            $(this).next('ul').stop(true, false, true).fadeToggle(300);
        }
    });
    $('.pt_brand .brand-nav > ul > li > ul > li a').click(function () {
        $(this).parent().children('ul').stop(false, false, true).fadeToggle(300, function () {
            if ($(this).is(':hidden')) {
                $(this).parent().find('> a .expand').html('+');
            }
        });
        if ($(this).is(':visible')) {
            $(this).find('.expand').html('-');
        }
    });
    $('.pt_brand .brand-nav > ul > li > a').find('~ ul > li:last > a, ~ ul li:last > a').on('keydown', function (e) {
        if (e.which === 9 && !e.shiftKey) {
            if (!($(this).children('.expand').html() == '-')) {
                $(this).parents('.brand-nav > ul > li > ul').stop(true, false, true).fadeOut(300);
            }
        }
    });
}

function faqElements() {
    if ($('.faq').length > 0) {
        $('.faq p').hide();
        $('.faq h4:not(.standalone)').attr('tabindex', 0).attr('title', 'Click to reveal answer').click(function () {
            $(this).next('.faq p').slideToggle(300);
            $(this).toggleClass('expanded');
        });
        $('.faq h4').on('keydown', function (e) {
            if (e.which === 13) {
                $(this).click();
            }
        });
    }
}

var brand = {
    init: function () {
        brandMenu();
        faqElements();
    }
};

module.exports = brand;
